<template>
  <div style="width:100%;padding:10px;">
    <v-layout row wrap style="margin:0 0 10px 0;">
      <v-flex xs6 sm6>
        <h1>{{ $t("movies.header") }}</h1>
      </v-flex>
    </v-layout>
    <MoviesSearch></MoviesSearch>
    <MoviesLibrary></MoviesLibrary>
  </div>
</template>

<script>
import MoviesSearch from "../components/movies/MoviesSearch";
import MoviesLibrary from "../components/movies/MoviesLibrary";

export default {
  components: {
    MoviesSearch,
    MoviesLibrary
  }
};
</script>

<style>
</style>