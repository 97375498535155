<template>
  <div>
    <h2>{{ $t("movies.library") }}</h2>
    <v-layout row wrap>
      <v-flex xs6 sm4 md3 lg2 v-for="movie in movies" :key="movie.id">
        <MoviesCard
          :id="movie.data.tmdb_id"
          :title="movie.data.movie_title"
          :year="(new Date(movie.data.movie_release_date)).getFullYear()"
          :poster="movie.data.movie_poster_path"
        ></MoviesCard>
      </v-flex>
    </v-layout>
    <v-card v-intersect="infiniteScrolling"></v-card>
  </div>
</template>

<script>
import MoviesCard from "./MoviesCard";
import { storage } from "../../schmucklicloud";
export default {
  data() {
    return {
      movies: [],

      last_movies_length: 0
    };
  },
  mounted() {
    this.loadLibraryMovies(this.movies.length, 15);
  },
  methods: {
    infiniteScrolling() {
        if (this.last_movies_length === this.movies.length) {
            return;
        }else {
            this.last_movies_length = this.movies.length;
            setTimeout(function() {
                this.loadLibraryMovies(this.movies.length, 15);
            }.bind(this), 1000);
        }
    },
    async loadLibraryMovies(start, limit) {
      var response = await storage.getAll(
        "movies",
        [
          {
            column: "watched_year",
            order: "desc"
          },
          {
            column: "watched_month",
            order: "desc"
          }
        ],
        start,
        limit
      );
      if (response.isOK) {
        if (response.data !== undefined) {
          this.movies = this.movies.concat(response.data);
        }
      }
    }
  },
  components: {
    MoviesCard
  }
};
</script>

<style>
</style>