<template>
  <div>
      <v-text-field solo v-model="movies_search" @keydown="startSearch" :label="$t('movies.search_for_movies')"></v-text-field>
      <div class="results">
          <v-layout row wrap>
              <v-flex xs6 sm4 md3 lg2 v-for="movie in movies" :key="movie.id">
                  <MovieCard :id="movie.id" :title="movie.title" :year="(new Date(movie.release_date)).getFullYear()" :poster="movie.poster_path"></MovieCard>
              </v-flex>
          </v-layout>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import { THEMOVIEDB_API_KEY } from "../../keys";

import MovieCard from "./MoviesCard";

export default {
    data() {
        return {
            movies_search: "",

            movies: []
        }
    },
    methods: {
        startSearch(e) {
            var keycode = e.keyCode ? e.keyCode : e.which;
            if (keycode == '13' && this.movies_search !== "") {
                this.searchMovie(this.movies_search);
            }
        },
        async searchMovie(query) {
            var response = await axios({
                url: "https://api.themoviedb.org/3/search/movie?api_key=" + THEMOVIEDB_API_KEY + "&query=" + encodeURI(query) + "&language=de"
            });

            if (response.status === 200) {
                this.movies = response.data.results;
            }
        }
    },
    components: {
        MovieCard
    }
}
</script>

<style>

</style>